import React from 'react'
import styles from './Footer.Module.css';

export function Footer() {
  return (
    <div className={styles.container}>
      <h4>© Copyrigth Todos los derechos reservados</h4>
      <p>App realizada por Javier Garbalena</p>
    </div>
  )
}
